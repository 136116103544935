<template>
    <NotasFiscais />
</template>

<script lang="js">
    import NotasFiscais from '../../components/download/NotasFiscais.vue'

    export  default {
        components: { NotasFiscais }
    }

</script>