<template>
	<div class="col-12">
	<div class="card">
		<h5>Nota Fiscal</h5>			
		<DataTable :value="databases" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
				v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="stack"
				:globalFilterFields="['numeroNotaFiscal','localEntrega','dataEmissaoNota','valorTotalNota']" >
			<template #header>
				<div class="flex justify-content-between flex-column sm:flex-row">
                    <div class="flex">
						<Calendar :showIcon="true" 
								:showButtonBar="true"
								dateFormat="dd/mm/yy" 
								class="mb-2" 
								v-model="dataInicial" />
						
						<Calendar :showIcon="true" 
								:showButtonBar="true" 
								v-model="dataFinal" 
								dateFormat="dd/mm/yy" 
								class="mb-2 ml-3" 
								:minDate="dataInicial" />

						<Button label="Consultar" 
								icon="pi pi-search" 
								class="mb-2 ml-3" 
								@click="getNotas()" />
						
						<Button type="button" 
								icon="pi pi-filter-slash" 
								label="Limpar" 
								class="p-button-outlined mb-2 ml-3" 
								@click="clearFilter1()" />
                    </div>
                    <div>
						<span class="p-input-icon-left mb-2">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" 
									placeholder="Procurar" 
									style="width: 100%" />
						</span>
                    </div>
                </div>
			</template>
			<template #empty>
				Nenhum registro encontrado.
			</template>
			<template #loading>
				Carregando... Por favor, aguarde.
			</template>

            <Column header="Baixar" style="text-align: center;">
				<template #body="data">
					<Button icon="pi pi-download" 
							@click="getDownload(data.data.codigoEstabelecimento, data.data.serie, data.data.numeroNotaFiscal)" 
							type="button" 
							class="mr-2" 
							label="Download" />
				</template>
			</Column>

			<Column field="numeroNotaFiscal" header="Núm. Nota" style="text-align: center;">
				<template #body="data">
					{{data.data.numeroNotaFiscal}}
				</template>
			</Column>
            <Column field="serie" header="Série" style="text-align: center;">
				<template #body="data">
                    {{data.data.serie}}
                </template>
			</Column>
            <Column field="dataEmissaoNota" header="Data Emissão" style="text-align: center;">
				<template #body="data">
                    {{formatarData(data.data.dataEmissaoNota)}}
                </template>
			</Column>
            <Column field="valor" header="Total Nota" style="text-align: right;">
				<template #body="data">
                    {{formatMoeda(data.data.valorTotalNota)}}
                </template>
			</Column>
             <Column field="localEntrega" header="Local Entrega" style="text-align: left;">
				<template #body="data">
                    {{data.data.localEntrega}}
                </template>
			</Column>
            <Column field="chaveAcesso" header="Chave Acesso" style="text-align: left; width: auto;">
				<template #body="data"><p style=" word-break: break-all;">
                    {{data.data.chaveAcesso}}
               </p> </template>
			</Column>
		</DataTable>

	</div>
	</div>
</template>

<script>
    import {FilterMatchMode} from 'primevue/api';
    import NotasFiscaisService from "../../service/NotaFiscalService"
	import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'ContasPagar', 

		props: {
			matricula: {
				type: String,
				required: true
			}
		},
        data(){
            return {
                databases: [],
                notafiscal: [],

				filters1: null,
				filters2: {},
				loading1: true,
				loading2: true,
                
				dataInicial: Formatacao.subtrairDias(new Date(), 30),
                dataFinal: new Date(),
            }
        },

        created(){
            this.initFilters1();
            this.getNotas();
        },
		mounted() {
			this.loading1 = false;
			this.loading2 = false;
		},
        methods: {
            initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'numeroNotaFiscal': {value: null, matchMode: FilterMatchMode.CONTAINS},	
                    'localEntrega': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'dataEmissaoNota': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'valorTotalNota': {value: null, matchMode: FilterMatchMode.CONTAINS},		
				}
			},
            getNotas() {
				let matricula = this.getCurrentUser().matricula;
				NotasFiscaisService.getNotas(matricula, 
						Formatacao.formatDateCustom(this.dataInicial, 'DD-MM-YYYY'), 
						Formatacao.formatDateCustom(this.dataFinal, 'DD-MM-YYYY'))
					.then((response) => {
                    	this.databases = response.data;
					}) ;
			},

            getDownload(matricula, codigoEspecie, codigoUnidadeNegocio) {
                NotasFiscaisService.getNotasDownload(matricula, codigoEspecie, codigoUnidadeNegocio).then((response) => {
                    let name = "Nota Fiscal";
                    let base64String = response.data.bytes;
                    const datasource = "data:application/pdf;base64," + base64String;
                    const link = document.createElement("a");
                    link.href = datasource;
                    link.download = name;
                    link.click();
				}) ;

                
			},

			clearFilter1() {
				this.initFilters1();
			},
			formatMoeda(value) {
				return value ? value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) : '';
			},

			formatarData(data) { 
                var options = { year: 'numeric', month: 'numeric', day: 'numeric' }; options.timeZone = 'UTC';             
                return new Date(data).toLocaleDateString("pt-BR", options);
			},

			getCurrentUser() {
				return this.$store.state.auth.user;
			},
        }       
    }
    
</script>
