import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const NOTAS_API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/nota-fiscal`;

class NotaFiscalService {

    getNotas(matricula, data1, data2) {
        return axios.get(NOTAS_API_BASE_URL + RESOURCE_V1 + "/" + matricula + "/" + data1 + "/" + data2);
    }

    getNotasDownload(estabelecimento, serie, numero) {
        return axios.get(NOTAS_API_BASE_URL + RESOURCE_V1 + "/download/" + estabelecimento + "/" + serie + "/" + numero );
    }
}

export default new NotaFiscalService(); 